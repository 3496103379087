import { NavLink } from "react-router-dom";
import servicesList from "../../../data/service.json";

const MainMenu = () => {
  return (
    <nav className="main-menu d-none d-lg-block">
      <ul className="d-flex">
        <li>
          <NavLink exact to="/">
            Home
          </NavLink>
        </li>

        <li>
          <NavLink to={process.env.PUBLIC_URL + "/service"}>Service</NavLink>

          <ul className="sub-menu">
            {servicesList.map((el, index) => (
              <li className="sub-menu-item">
                <NavLink
                  className="sub-menu-link"
                  key={index}
                  to={process.env.PUBLIC_URL + `/service-details/${el.slug}`}
                >
                  {el.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/about"}>About</NavLink>
          <ul className="sub-menu">
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/team"}
              >
                Meet Our Team
              </NavLink>
            </li>
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/faq"}
              >
                Top FAQS
              </NavLink>
            </li>
          </ul>
        </li>
        {/* <li>
          <NavLink to="/">Pages</NavLink>
          <ul className="sub-menu">
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/faq"}
              >
                faq
              </NavLink>
            </li>
            <li className="sub-menu-item">
              <NavLink
                className="sub-menu-link"
                to={process.env.PUBLIC_URL + "/team"}
              >
                team
              </NavLink>
            </li>
          </ul>
        </li> */}
        {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/blog"}>
                        Blog
                    </NavLink>
                    <ul className="sub-menu">
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog"}
                            >
                                Blog
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/blog-left-sidebar"
                                }
                            >
                                blog grid left sidebar
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/blog-right-sidebar"
                                }
                            >
                                blog grid right sidebar
                            </NavLink>
                        </li>
                        <li className="sub-menu-item">
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog-details/1"}
                            >
                                blog details
                            </NavLink>
                        </li>
                    </ul>
                </li> */}
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/contact"}>Contact Us</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
