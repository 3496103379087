import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Team from "../../../components/team";
import HomeData from "../../../data/home.json";

const TeamContainer = ({ classOption }) => {
  return (
    <div className={`team-section overflow-hidden ${classOption}`}>
      <div className="container position-relative">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="team-content-wrap">
              <SectionTitle
                classOption="title-section"
                subTitle="TEAM"
                title="People, <span class='text-primary'>Behind</span> the Screen"
                excerptClassOption="mb-5"
                excerpt="Team members are the driving force behind innovation. They collaborate, code, design, and manage to create cutting-edge solutions. Each individual's expertise and unique perspective contribute to a dynamic and creative environment, ensuring the company's success in a fast-paced tech landscape."
              />
              <p className="high-light mb-8">
                Discover Our Winning Formula: At our software development
                company, brilliant minds unite to craft ingenious solutions.
              </p>
              <Button
                classOption="btn btn-lg btn-dark btn-hover-dark"
                text="View more"
                path="/team"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="new-team-members">
              {HomeData[5].team &&
                HomeData[5].team.map((single, key) => {
                  return <Team key={key} data={single} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TeamContainer.propTypes = {
  classOption: PropTypes.string,
};

TeamContainer.defaultProps = {
  classOption: "section-pb",
};

export default TeamContainer;
