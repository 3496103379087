import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-top position-relative">
        <img
          className="footer-shape"
          src={process.env.PUBLIC_URL + "/images/footer/1.png"}
          alt="shape"
        />
        <div className="container">
          <div className="row mb-n7">
            <div className="col-lg-3 col-sm-6 mb-7">
              <div className="footer-widget">
                <Logo
                  classOption="footer-logo mb-9"
                  image={`${process.env.PUBLIC_URL}/images/logo/logo-footer.png`}
                />
                <p>
                  Stay updated with our latest developments, industry insights,
                  and more. Follow us on social media for a deeper connection.
                </p>
                <ul className="footer-social-links">
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://facebook.com/parishsoftwares"
                      icon="icofont-facebook"
                    />
                  </li>
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://www.instagram.com/parishsoftwares"
                      icon="icofont-instagram"
                    />
                  </li>
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://twitter.com/parishsoftwares"
                      icon="icofont-twitter"
                    />
                  </li>
                  <li>
                    <SocialIcon
                      classOption="footer-social-link"
                      path="https://wa.me/+919509919106"
                      icon="icofont-whatsapp"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-7 offset-xl-1">
              <div className="footer-widget">
                <h4 className="title">Quick Link</h4>
                <ul className="footer-menu">
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + "/about"}
                    >
                      About us
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + "/about"}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + "/blog"}
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + "/contact"}
                    >
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6 mb-7">
              {/* <div className="footer-widget">
                <h4 className="title">Services</h4>
                <ul className="footer-menu">
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + `/service-details/2`}
                    >
                      Business Plan
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + `/service-details/3`}
                    >
                      Creative Strategy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + `/service-details/4`}
                    >
                      Branding{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + `/service-details/1`}
                    >
                      Digital Solution
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + `/service-details/5`}
                    >
                      Marketing Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="footer-link"
                      to={process.env.PUBLIC_URL + `/service-details/6`}
                    >
                      Campaign &amp; PR
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-lg-3 col-sm-6 mb-7">
              <div className="footer-widget">
                <h4 className="title">Contact info</h4>
                <p>222 Unnati Tower, Central Spine, Jaipur(Rajasthan), India</p>
                <ul className="address">
                  <li>
                    <a className="address-link" href="tel:+919509919106">
                      +91 9509919106
                    </a>
                  </li>
                  <li>
                    <a className="address-link" href="tel:+919509919107">
                      +91 9509919107
                    </a>
                  </li>
                  <li>
                    <a
                      className="address-link"
                      href="mailto:info@parishsoftwares.com"
                    >
                      info@parishsoftwares.com
                    </a>
                    <br />
                    <a
                      className="address-link"
                      href="mailto:sales@parishsoftwares.com"
                    >
                      sales@parishsoftwares.com
                    </a>
                  </li>
                  <li>
                    {/* <a className="address-link" href="mailto:info@example.com">
                      www.example.com
                    </a> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright-info text-center">
                <p>
                  Copyright &copy; 2023 Made with{" "}
                  <i className="icofont-heart-alt"></i> By{" "}
                  <a
                    href="https://parishsoftwares.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Parish Softwares
                  </a>
                  , All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
