import { useEffect, useRef } from "react";
import Button from "../../../components/button";
import SectionTitle from "../../../components/section-title";
import Parallax from "parallax-js";

const HomeAboutContainer = () => {
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="about-us position-relative">
      <div className="container">
        <div className="row mb-n7">
          <div className="col-xl-5 col-lg-6 mb-7">
            <div className="about-content">
              <SectionTitle
                classOption="title-section"
                subTitle="ABOUT US"
                title="Your Trusted 
                                <span class='text-primary'>
                                    Mobile App Development 
                                </span>
                                <br className='d-none d-xl-block' />
                                Partner"
              />
              <span className="date-meta">
                Since 2001 <span className="hr"></span>
              </span>
              <p className="mb-5">
                We are the experts in cross-platform web and mobile app
                development, seamlessly blending various platforms that enhance
                experiences. With our creative graphic design flair, we elevate
                projects to the next level.
              </p>
              <h1 className="high-light mb-8">
                Grow Your Business with Custom Mobile Apps - Hire a Mobile App
                Developer from a Leading Mobile App Development Company
              </h1>
              <Button
                classOption="btn btn-lg btn-dark btn-hover-dark"
                text="Learn more"
                path="/about"
              />
            </div>
          </div>
          <div className="col-xl-6 order-first order-lg-last col-lg-6 mb-7 offset-xl-1">
            <div
              className="about-photo scene text-center text-lg-left"
              id="scene"
              ref={sceneEl}
            >
              <div data-depth="0.2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/about/1.png`}
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="about-shape"
        src={`${process.env.PUBLIC_URL}/images/about/shape.png`}
        alt="bg-shape"
      />
    </div>
  );
};

export default HomeAboutContainer;
