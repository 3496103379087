import { useEffect, useRef } from "react";
import Button from "../../components/button";
import SectionTitle from "../../components/section-title";
import Parallax from "parallax-js";

const AboutContainer = () => {
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="about-us position-relative">
      <div className="container">
        <div className="row mb-n7 align-items-center">
          <div className="col-xl-5 col-md-6 offset-xl-1 mb-7">
            <div className="about-content">
              <SectionTitle
                classOption="title-section"
                subTitle="ABOUT US"
                title="Best <span class='text-primary'>
                                    Software Development Company</span>
                                <br className='d-none d-xl-block' />
                                in USA &amp; UK"
              />
              <span className="date-meta">
                Scince 2001 <span className="hr"></span>
              </span>
              <p className="mb-5">
                At Parish Softwares, we're not just service providers – we're
                partners in your digital journey. With mobile app development,
                website design, graphic design, and SEO strategies in our
                arsenal, we're equipped to elevate your brand's digital presence
                to new heights. Join us in shaping the future of your brand in
                the digital landscape.
              </p>
              <p className="high-light mb-8">
                Welcome to Parish Softwares – where your vision finds its
                digital home.
              </p>
              <Button
                classOption="btn btn-lg btn-dark btn-hover-dark"
                text="Learn more"
                path="/about"
              />
            </div>
          </div>
          <div className="col-xl-6 order-lg-first col-md-6 mb-7">
            <div
              className="about-photo scene text-center text-lg-left"
              id="scene"
              ref={sceneEl}
            >
              <div data-depth="0.2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/about/2.png`}
                  alt="about"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContainer;
